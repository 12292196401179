import React, { useEffect } from 'react'

import { RouteComponentProps } from '@reach/router'
import 'twin.macro'

import CalendlyInlineScheduler from 'components/shared/meeting/calendly-inline-scheduler'
import SEO from 'components/shared/seo'
import fireEvent from 'context/tracking/events'
import Layout from 'mx/layout'
import { MeetingTypeProject } from 'types/meeting'

const FreeConsultationBuildzoomInstallerPage = (
  props: RouteComponentProps<{
    location: {
      state: {
        email: string
        firstName: string
        lastName: string
      }
    }
  }>,
) => {
  let email = '',
    firstName = '',
    lastName = ''
  if (props.location?.state) {
    email = props.location.state.email
    firstName = props.location.state.firstName
    lastName = props.location.state.lastName
  }

  useEffect(() => {
    document.body.scrollTo({ top: 0 })
  })

  return (
    <Layout>
      <CalendlyInlineScheduler
        calendlyUrl={
          process.env.GATSBY_CALENDLY_BUILDZOOM_INSTALLER_URL as string
        }
        user={{
          email,
          firstName,
          lastName,
        }}
        onEventScheduled={() => {
          fireEvent({
            type: 'schedule_completed',
            meetingType: 'BUILDZOOM_INSTALLER',
          })
        }}
        type={MeetingTypeProject.BUILDZOOM_INSTALLER}
        utmSource="buildzoom"
        utmCampaign="installer"
      />
    </Layout>
  )
}

export default FreeConsultationBuildzoomInstallerPage

export const Head = () => (
  <SEO
    title="Free Consultation Buildzoom Installer"
    description="Free FORM Buildzoom Installer Consultation"
    path="/free-consultation-buildzoom-installer"
  />
)
